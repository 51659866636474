import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'home',
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/Home.vue'),
    meta: {
      title: '首页',
    }
  },
  {
    path: '/city_cd',
    name: 'chendu',
    component: () => import('@/components/city/chendu.vue'),
    meta: {
      title: '成都案例',
    }
  },
  {
    path: '/city_fs',
    name: 'chendu',
    component: () => import('@/components/city/foshan.vue'),
    meta: {
      title: '佛山案例',
    }
  },
]

const router = new VueRouter({
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

export default router
