<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
    window.addEventListener(
  "mousewheel",
  function (event) {
    if (event.ctrlKey === true || event.metaKey) {
      event.preventDefault();
    }
  },
  { passive: false }
);

//firefox
window.addEventListener(
  "DOMMouseScroll",
  function (event) {
    if (event.ctrlKey === true || event.metaKey) {
      event.preventDefault();
    }
  },
  { passive: false }
);
</script>

<style lang="less">
  *{
    padding: 0;
    margin: 0;
    list-style: none;
    text-decoration: none;
    
  }
</style>
