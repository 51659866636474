import axios from '../axios'

/*
 * 企业管理
 */


// 申请试用
export const applyOpen = (params) => {

    console.log(params)

    let response = axios('post','/company/api/company/apply_for_cooperate',params);
    return response;
};
